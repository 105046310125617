import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase'
import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import 'primeflex/primeflex.css';
// import 'primeflex/src/_variables.scss';
// import 'primeflex/src/_grid.scss';
// import 'primeflex/src/_formlayout.scss';
// import 'primeflex/src/_display.scss';
// import 'primeflex/src/_text.scss';
// import 'primeflex/src/flexbox/_flexbox.scss';
// import 'primeflex/src/_spacing.scss';
// import 'primeflex/src/_elevation.scss';

const firebaseConfig = {
    apiKey: "AIzaSyD9Ljp7CistTs45r0-NtppFXiCgKmSO66M",
    authDomain: "ploutus-base.firebaseapp.com",
    projectId: "ploutus-base",
    storageBucket: "ploutus-base.appspot.com",
    messagingSenderId: "344258420244",
    appId: "1:344258420244:web:e1f65e1891c496ddc51101",
    measurementId: "G-TR9HZLLMS9"
  };

firebase.initializeApp(firebaseConfig);

createApp(App).use(router,PrimeVue).mount('#app')
