<template>
  <router-view/>
</template>

<script>
import {onBeforeMount} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import firebase from 'firebase';

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    onBeforeMount(()=>{
      firebase.auth().onAuthStateChanged((user) =>{
        if(!user){
          router.replace('login');
        } else if(route.path == "/login"){
          router.replace('/pin')
        }
      })
    })
  },
}
</script>


<style>
body{
  background-color: #2c3e50;
  color: #fff;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a{
  color: #fff;
}

.container{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.font-black{
    color: #000;
}
.no-decor{
    text-decoration: none;
}
.text-justify{
    text-align: justify;
}
</style>
