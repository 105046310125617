import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
    // component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/pin',
    name: 'Pin',
    component: () => import('../views/Pin.vue')
  },
  {
    path: '/managers',
    name: 'Managers',
    component: () => import('../views/Managers.vue')
  },
  {
    path: '/domains',
    name: 'Domains',
    component: () => import('../views/Domains.vue')
  },
  {
    path: '/editdomain/:id',
    name: 'EditDomain',
    component: () => import('../views/EditDomain.vue')
  },
  {
    path: '/editnews/:id',
    name: 'EditNews',
    component: () => import('../views/EditNews.vue')
  },
  {
    path: '/edittodo/:id',
    name: 'EditTodo',
    component: () => import('../views/EditTodo.vue')
  },
  {
    path: '/admins',
    name: 'Admins',
    component: () => import('../views/Admins.vue')
  },
  {
    path: '/forms',
    name: 'Forms',
    component: () => import('../views/Forms.vue')
  },
  {
    path: '/videos',
    name: 'Videos',
    component: () => import('../views/Videos.vue')
  },
  {
    path: '/activity',
    name: 'Activity',
    component: () => import('../views/Activity.vue')
  },
  {
    path: '/todos',
    name: 'Todos',
    component: () => import('../views/Todos.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
